<template>
    <div class="cart-view">
        <ecommerce-hero title="Your Cart">

        </ecommerce-hero>
        <div class="uk-container-large uk-margin-auto uk-padding-small">
            <ul class="uk-breadcrumb uk-margin-remove-bottom">
                <li><a href="#shop">Shop</a></li>
                <li class="uk-disabled"><a>Cart</a></li>
            </ul>
        </div>
        <order-summary summaryPosition="bottom"
                       title="Your Cart"
                       :mode="'currentCart'"
                      :showPricePerEntry="true"
                      :showPriceSummeryByType="true"
                      :extended="true"></order-summary>


        <div class="uk-container-large uk-margin-auto uk-margin-large-bottom">
            <div class="uk-text-success uk-text-center uk-margin-top uk-text-semibold uk-flex uk-flex-middle uk-flex-center">
                <icon-text icon="lock">Secure Payment</icon-text>
            </div>
            <form-button :full-width="true"
                         icon-end="chevron-right"
                         @click="$router.push({name: 'checkout'})"
                         class="uk-margin-top">CONTINUE TO CHECKOUT</form-button>

            <div class="uk-text-success uk-text-center uk-margin-top uk-text-semibold uk-flex uk-flex-middle uk-flex-center uk-margin-bottom">
                <img src="/img/credit_card_logos.jpg" />
            </div>
        </div>


    </div>



</template>

<script>
    import ecommerceHero from "@/client/components/ecommerce/Hero.vue";
    import asyncOperations from "@/client/extensions/composition/asyncOperations";
    import OrderSummary from "@/client/components/ecommerce/OrderSummary.vue";
    export default {
        props: {
            heroBackground: {
                default: null
            }
        },
        components: {
            ecommerceHero, OrderSummary
        },
        data: function () {
            return {

           };
        },
        computed: {


        },
        methods: {

        },
        mounted () {

        },
    }
</script>

<style scoped lang="scss">


</style>
